// src/forms/ContactForm/ContactForm.tsx
import { useForm } from "react-hook-form";

// src/forms/useSendTemplatedEmailMutation.ts
import { useMutation } from "@tanstack/react-query";
var SEND_TEMPLATED_EMAIL_API = "/apiproxy/TemplatedEmail/sendtemplatedemail";
var sendTemplatedEmailFetcher = async (input) => {
  let response;
  const { token, templateName, data, attachments } = input;
  try {
    response = await fetch(SEND_TEMPLATED_EMAIL_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        __RequestVerificationToken: token
      },
      body: JSON.stringify({
        EmailTemplateName: templateName,
        Parameters: data,
        ...attachments ? {
          AttachmentIds: attachments
        } : {}
      })
    });
  } catch (e) {
    throw new Error("Unable to reach backend");
  }
  if (!response.ok) {
    throw new Error("Something went wrong while sending the email");
  }
  return {};
};
var useSendTemplatedEmailMutation = (options) => useMutation(
  ["sendTemplatedEmail"],
  (input) => sendTemplatedEmailFetcher(input),
  options
);
var useSendTemplatedEmailMutation_default = useSendTemplatedEmailMutation;

// src/forms/ContactForm/ContactForm.tsx
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { zodResolver } from "@hookform/resolvers/zod";

// src/forms/ContactForm/schema.ts
import { z } from "zod";
var contactFormSchema = z.object({
  firstName: z.string({
    required_error: "this-field-is-required"
    // Written down as the translate keys
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  lastName: z.string({
    required_error: "this-field-is-required"
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  phone: z.string().regex(
    /^(((\\+31|0|0031)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))|(((\\+31|0|0031)6){1}[-]?[1-9]{1}[0-9]{7})$/,
    {
      message: "this-is-not-a-valid-dutch-phone-number"
    }
  ),
  email: z.string({
    required_error: "this-field-is-required"
  }).email({ message: "this-is-not-a-valid-email" }),
  subject: z.string(),
  message: z.string()
}).required({
  firstName: true,
  lastName: true,
  email: true
});

// src/forms/ContactForm/ContactForm.tsx
import { Input, TextArea, FormGroup, ArrowRightIcon } from "@achmea/ui";
import { useTranslation } from "react-i18next";
import { jsx, jsxs } from "react/jsx-runtime";
var ContactForm = (props) => {
  var _a, _b, _c, _d, _e, _f;
  const {
    backOfficeMailTemplate,
    confirmationMailTemplate,
    buttonText,
    formSentText,
    className = "",
    ...restProps
  } = props;
  const { sitecoreContext } = useSitecoreContext();
  const requestVerificationToken = sitecoreContext.requestVerificationToken;
  const { t } = useTranslation("features");
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(contactFormSchema)
  });
  const sendBackofficeEmail = useSendTemplatedEmailMutation_default();
  const sendConfirmationEmail = useSendTemplatedEmailMutation_default();
  const isLoading = sendBackofficeEmail.isLoading || sendConfirmationEmail.isLoading;
  const isError = sendBackofficeEmail.isError || sendConfirmationEmail.isError;
  const isSuccess = sendBackofficeEmail.isSuccess && sendConfirmationEmail.isSuccess;
  const onSubmit = async (data) => {
    await sendBackofficeEmail.mutateAsync({
      token: requestVerificationToken,
      templateName: backOfficeMailTemplate,
      data
    });
    await sendConfirmationEmail.mutateAsync({
      token: requestVerificationToken,
      templateName: confirmationMailTemplate,
      data
    });
  };
  return /* @__PURE__ */ jsxs("div", { className: `${className}`, ...restProps, children: [
    /* @__PURE__ */ jsxs("form", { onSubmit: handleSubmit(onSubmit), className: "form", children: [
      /* @__PURE__ */ jsx(FormGroup, { label: t("first-name"), error: (_a = errors.firstName) == null ? void 0 : _a.message, children: /* @__PURE__ */ jsx(Input, { ...register("firstName") }) }),
      /* @__PURE__ */ jsx(FormGroup, { label: t("last-name"), error: (_b = errors.lastName) == null ? void 0 : _b.message, children: /* @__PURE__ */ jsx(Input, { ...register("lastName") }) }),
      /* @__PURE__ */ jsx(FormGroup, { label: t("phone-number"), error: (_c = errors.phone) == null ? void 0 : _c.message, children: /* @__PURE__ */ jsx(Input, { ...register("phone") }) }),
      /* @__PURE__ */ jsx(FormGroup, { label: t("email"), error: (_d = errors.email) == null ? void 0 : _d.message, children: /* @__PURE__ */ jsx(Input, { ...register("email") }) }),
      /* @__PURE__ */ jsx(FormGroup, { label: t("subject"), error: (_e = errors.subject) == null ? void 0 : _e.message, children: /* @__PURE__ */ jsx(Input, { ...register("subject") }) }),
      /* @__PURE__ */ jsx(FormGroup, { label: t("message"), error: (_f = errors.message) == null ? void 0 : _f.message, children: /* @__PURE__ */ jsx(TextArea, { ...register("message") }) }),
      /* @__PURE__ */ jsxs(
        "button",
        {
          className: "btn btn_primary",
          type: "submit",
          "aria-label": `${t("sends-form")}`,
          disabled: isLoading || isSuccess,
          children: [
            buttonText || t("send"),
            /* @__PURE__ */ jsx(ArrowRightIcon, {})
          ]
        }
      )
    ] }),
    isError && /* @__PURE__ */ jsx("div", { className: "rich-text mt-8", children: t("something-went-wrong-while-sending-your-form") }),
    isSuccess && /* @__PURE__ */ jsx("div", { className: "rich-text mt-8", children: formSentText || t("your-message-has-been-sent") })
  ] });
};
var ContactForm_default = ContactForm;

// src/forms/ContactFormWithAttachments/ContactFormWithAttachments.tsx
import { FormProvider, useForm as useForm2 } from "react-hook-form";
import { useSitecoreContext as useSitecoreContext2 } from "@sitecore-jss/sitecore-jss-react";
import { zodResolver as zodResolver2 } from "@hookform/resolvers/zod";

// src/forms/ContactFormWithAttachments/schema.ts
import { z as z2 } from "zod";
import { hasValidFileType } from "@achmea/ui";
var contactFormWithAttachmentsSchema = (maxFiles, minFileSize, maxFileSize, validExtensions) => z2.object({
  firstName: z2.string().min(1, { message: "this-field-is-required" }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  lastName: z2.string().min(1, { message: "this-field-is-required" }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  phone: z2.string().regex(
    /^(\+|00)?[1-9]\d{0,2}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
    {
      message: "this-is-not-a-valid-phone-number"
    }
  ),
  email: z2.string().min(1, { message: "this-field-is-required" }).email({ message: "this-is-not-a-valid-email" }),
  subject: z2.string(),
  message: z2.string(),
  attachments: z2.array(
    z2.object({
      attachmentId: z2.string(),
      file: z2.any().refine((file) => hasValidFileType(file.name, validExtensions), "filetype-does-not-match").refine((file) => file.size >= (minFileSize || 0) * 1024, "files-are-too-small").refine((file) => file.size <= (maxFileSize || Infinity) * 1024, "files-are-too-big")
    })
  ).max(maxFiles || Infinity, {
    message: "too-many-files"
  })
});

// src/forms/ContactFormWithAttachments/ContactFormWithAttachments.tsx
import { Input as Input2, TextArea as TextArea2, FormGroup as FormGroup2, getFieldArrayErrors } from "@achmea/ui";
import { useTranslation as useTranslation3 } from "react-i18next";

// src/forms/UploadInput/UploadInput.tsx
import { forwardRef, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation as useTranslation2 } from "react-i18next";

// src/forms/useUploadFileMutation.ts
import { useMutation as useMutation2 } from "@tanstack/react-query";
var UPLOAD_FILE_API = "/apiproxy/TemplatedEmail/UploadFile";
var uploadFileFetcher = async (input) => {
  let response;
  const { token, file } = input;
  const body = new FormData();
  body.append("File", file);
  try {
    response = await fetch(UPLOAD_FILE_API, {
      method: "POST",
      headers: {
        __RequestVerificationToken: token
      },
      body
    });
  } catch (e) {
    throw new Error("Unable to reach backend");
  }
  if (!response.ok) {
    throw new Error("Something went wrong while uploading the file");
  }
  return response.json();
};
var useUploadFileMutation = (options) => useMutation2(
  ["uploadFile"],
  (input) => uploadFileFetcher(input),
  options
);
var useUploadFileMutation_default = useUploadFileMutation;

// src/forms/UploadInput/UploadInput.tsx
import { FileDropzone } from "@achmea/ui";
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var UploadInput = forwardRef(
  (props, ref) => {
    const {
      name,
      maxFiles = 1,
      minFileSize,
      maxFileSize,
      validExtensions,
      className,
      ...restProps
    } = props;
    const { control, register, trigger } = useFormContext();
    const uploadFile = useUploadFileMutation_default();
    const isLoading = uploadFile.isLoading;
    const isError = uploadFile.isError;
    const { t } = useTranslation2("features");
    const { fields, append, remove } = useFieldArray({
      name,
      control
    });
    useEffect(() => {
      trigger(name);
    }, [fields]);
    const handleFile = async (file) => {
      append({ file });
    };
    return /* @__PURE__ */ jsxs2("div", { className: "input upload-input", children: [
      isLoading ? t("uploading-file") : null,
      isError ? t("error-while-uploading-file") : null,
      /* @__PURE__ */ jsx2(FileDropzone, { onChange: handleFile }),
      /* @__PURE__ */ jsx2("div", { className: "upload-input__file-list file-list", children: fields.map((field, index) => /* @__PURE__ */ jsxs2("div", { className: "file-list__file", children: [
        /* @__PURE__ */ jsx2(
          "input",
          {
            type: "string",
            className: "hidden",
            ...register(`${name}.${index}.attachmentId`)
          }
        ),
        /* @__PURE__ */ jsxs2("div", { children: [
          fields[index].file.name,
          " (",
          (fields[index].file.size / 1024).toFixed(1),
          " ",
          "kilobytes)"
        ] }),
        /* @__PURE__ */ jsx2(
          "button",
          {
            onClick: () => remove(index),
            className: "btn btn_primary mt-2",
            children: t("reset-attachment")
          }
        )
      ] }, field.id)) }),
      /* @__PURE__ */ jsxs2("div", { className: "mt-4", children: [
        validExtensions && /* @__PURE__ */ jsx2("div", { children: t("allowed-file-types", {
          fileTypes: validExtensions
        }) }),
        minFileSize && /* @__PURE__ */ jsx2("div", { children: t("minimum-filesize", {
          minFileSize,
          unit: "kilobytes"
        }) }),
        maxFileSize && /* @__PURE__ */ jsx2("div", { children: t("maximum-filesize", {
          maxFileSize,
          unit: "kilobytes"
        }) }),
        maxFiles > 1 && /* @__PURE__ */ jsx2("div", { children: t("maximum-files-amount", {
          maxFiles
        }) })
      ] })
    ] });
  }
);
UploadInput.displayName = "UploadInput";
var UploadInput_default = UploadInput;

// src/forms/ContactFormWithAttachments/ContactFormWithAttachments.tsx
import { LoadingIcon } from "@achmea/ui";
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
var ContactFormWithAttachments = (props) => {
  var _a, _b, _c, _d, _e, _f;
  const {
    backOfficeMailTemplate,
    confirmationMailTemplate,
    buttonText,
    formSentText,
    minFileSize,
    maxFileSize,
    maxFiles,
    validExtensions,
    className = "",
    ...restProps
  } = props;
  const { sitecoreContext } = useSitecoreContext2();
  const requestVerificationToken = sitecoreContext.requestVerificationToken;
  const { t } = useTranslation3("features");
  const validFileTypes = validExtensions ? validExtensions.split(", ") : [];
  const form = useForm2({
    resolver: zodResolver2(
      contactFormWithAttachmentsSchema(
        maxFiles,
        minFileSize,
        maxFileSize,
        validFileTypes
      )
    ),
    mode: "all"
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form;
  const uploadFile = useUploadFileMutation_default();
  const sendBackofficeEmail = useSendTemplatedEmailMutation_default();
  const sendConfirmationEmail = useSendTemplatedEmailMutation_default();
  const isLoading = uploadFile.isLoading || sendBackofficeEmail.isLoading || sendConfirmationEmail.isLoading;
  const isError = uploadFile.isError || sendBackofficeEmail.isError || sendConfirmationEmail.isError;
  const isSuccess = sendBackofficeEmail.isSuccess && sendConfirmationEmail.isSuccess;
  const onSubmit = async (data) => {
    const body = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      subject: data.subject,
      message: data.message
    };
    const attachmentPromises = data.attachments.map((attachment) => uploadFile.mutateAsync(
      {
        token: requestVerificationToken,
        file: attachment.file
      }
    ));
    await Promise.all(attachmentPromises).then(async (attachments) => {
      await sendBackofficeEmail.mutateAsync({
        token: requestVerificationToken,
        templateName: backOfficeMailTemplate,
        data: body,
        attachments: attachments.map((attachment) => attachment.attachmentId)
      });
      await sendConfirmationEmail.mutateAsync({
        token: requestVerificationToken,
        templateName: confirmationMailTemplate,
        data: body
      });
    });
  };
  const attachmentsErrors = getFieldArrayErrors(
    errors.attachments,
    (field) => {
      var _a2;
      return [(_a2 = field.file) == null ? void 0 : _a2.message].filter(Boolean);
    }
  );
  return /* @__PURE__ */ jsxs3("div", { className: `${className}`, ...restProps, children: [
    /* @__PURE__ */ jsx3(FormProvider, { ...form, children: /* @__PURE__ */ jsxs3("form", { onSubmit: handleSubmit(onSubmit), className: "form", children: [
      /* @__PURE__ */ jsx3(FormGroup2, { label: t("first-name"), error: (_a = errors.firstName) == null ? void 0 : _a.message, children: /* @__PURE__ */ jsx3(Input2, { ...register("firstName") }) }),
      /* @__PURE__ */ jsx3(FormGroup2, { label: t("last-name"), error: (_b = errors.lastName) == null ? void 0 : _b.message, children: /* @__PURE__ */ jsx3(Input2, { ...register("lastName") }) }),
      /* @__PURE__ */ jsx3(FormGroup2, { label: t("phone-number"), error: (_c = errors.phone) == null ? void 0 : _c.message, children: /* @__PURE__ */ jsx3(Input2, { ...register("phone") }) }),
      /* @__PURE__ */ jsx3(FormGroup2, { label: t("email"), error: (_d = errors.email) == null ? void 0 : _d.message, children: /* @__PURE__ */ jsx3(Input2, { ...register("email") }) }),
      /* @__PURE__ */ jsx3(FormGroup2, { label: t("subject"), error: (_e = errors.subject) == null ? void 0 : _e.message, children: /* @__PURE__ */ jsx3(Input2, { ...register("subject") }) }),
      /* @__PURE__ */ jsx3(FormGroup2, { label: t("message"), error: (_f = errors.message) == null ? void 0 : _f.message, children: /* @__PURE__ */ jsx3(TextArea2, { ...register("message") }) }),
      /* @__PURE__ */ jsx3(
        FormGroup2,
        {
          as: "div",
          label: t("attachments"),
          error: attachmentsErrors,
          children: /* @__PURE__ */ jsx3(
            UploadInput_default,
            {
              name: "attachments",
              maxFiles,
              minFileSize,
              maxFileSize,
              validExtensions
            }
          )
        }
      ),
      /* @__PURE__ */ jsx3(
        "button",
        {
          className: "btn btn_primary",
          type: "submit",
          "aria-label": `${t("sends-form")}`,
          disabled: isLoading || isSuccess,
          children: isLoading ? /* @__PURE__ */ jsx3(LoadingIcon, {}) : buttonText || t("send")
        }
      )
    ] }) }),
    isError && /* @__PURE__ */ jsx3("div", { className: "rich-text mt-8", children: t("something-went-wrong-while-sending-your-form") }),
    isSuccess && /* @__PURE__ */ jsx3("div", { className: "rich-text mt-8", children: formSentText || t("your-message-has-been-sent") })
  ] });
};
var ContactFormWithAttachments_default = ContactFormWithAttachments;

// src/forms/ContactFormBlock/ContactFormBlock.tsx
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { PageBlockWrapper, RichText, useIsFieldValid } from "@achmea/ui";
import { jsx as jsx4, jsxs as jsxs4 } from "react/jsx-runtime";
var DEFAULT_BLOCK_VARIANT = "white";
var DEFAULT_SHOW_DECORATIONS = true;
var ContactFormBlock = (props) => {
  var _a, _b;
  const { fields, className = "", ...restProps } = props;
  const variant = getFieldValue(
    fields,
    "BlockVariant",
    DEFAULT_BLOCK_VARIANT
  );
  const showDecorations = getFieldValue(
    fields,
    "ShowDecorations",
    DEFAULT_SHOW_DECORATIONS
  );
  const { isTextFieldValid, isRichTextFieldValid } = useIsFieldValid();
  const showText = isTextFieldValid(fields.Title) || isRichTextFieldValid(fields.RichTextSimple);
  return /* @__PURE__ */ jsx4(
    PageBlockWrapper,
    {
      variant,
      showDecorations,
      className: `contact-form-block ${className}`,
      ...restProps,
      children: /* @__PURE__ */ jsxs4("div", { className: "contact-form-block__container", children: [
        showText && /* @__PURE__ */ jsxs4("div", { className: "mb-6 xl:mb-8", children: [
          /* @__PURE__ */ jsx4(
            Text,
            {
              tag: "h2",
              field: fields.Title,
              className: `heading-2 ${isRichTextFieldValid(fields.RichTextSimple) ? "mb-3" : ""}`
            }
          ),
          /* @__PURE__ */ jsx4(RichText, { field: fields.RichTextSimple })
        ] }),
        /* @__PURE__ */ jsx4(
          ContactForm_default,
          {
            backOfficeMailTemplate: fields.BackOfficeMailTemplate.value,
            confirmationMailTemplate: fields.ConfirmationMailTemplate.value,
            buttonText: (_a = fields.ButtonText) == null ? void 0 : _a.value,
            formSentText: (_b = fields.FormSentText) == null ? void 0 : _b.value
          }
        )
      ] })
    }
  );
};
var ContactFormBlock_default = ContactFormBlock;

// src/forms/ContactFormWithAttachmentsBlock/ContactFormWithAttachmentsBlock.tsx
import { Text as Text2, getFieldValue as getFieldValue2 } from "@sitecore-jss/sitecore-jss-react";
import { PageBlockWrapper as PageBlockWrapper2, RichText as RichText2, useIsFieldValid as useIsFieldValid2 } from "@achmea/ui";
import { jsx as jsx5, jsxs as jsxs5 } from "react/jsx-runtime";
var DEFAULT_BLOCK_VARIANT2 = "white";
var DEFAULT_SHOW_DECORATIONS2 = true;
var ContactFormWithAttachmentsBlock = (props) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { fields, className = "", ...restProps } = props;
  const variant = getFieldValue2(
    fields,
    "BlockVariant",
    DEFAULT_BLOCK_VARIANT2
  );
  const showDecorations = getFieldValue2(
    fields,
    "ShowDecorations",
    DEFAULT_SHOW_DECORATIONS2
  );
  const { isTextFieldValid, isRichTextFieldValid } = useIsFieldValid2();
  const showText = isTextFieldValid(fields.Title) || isRichTextFieldValid(fields.RichTextSimple);
  return /* @__PURE__ */ jsx5(
    PageBlockWrapper2,
    {
      variant,
      showDecorations,
      className: `contact-form-block ${className}`,
      ...restProps,
      children: /* @__PURE__ */ jsxs5("div", { className: "contact-form-block__container", children: [
        showText && /* @__PURE__ */ jsxs5("div", { className: "mb-6 xl:mb-8", children: [
          /* @__PURE__ */ jsx5(
            Text2,
            {
              tag: "h2",
              field: fields.Title,
              className: `heading-2 ${isRichTextFieldValid(fields.RichTextSimple) ? "mb-3" : ""}`
            }
          ),
          /* @__PURE__ */ jsx5(RichText2, { field: fields.RichTextSimple })
        ] }),
        /* @__PURE__ */ jsx5(
          ContactFormWithAttachments_default,
          {
            backOfficeMailTemplate: fields.BackOfficeMailTemplate.value,
            confirmationMailTemplate: fields.ConfirmationMailTemplate.value,
            buttonText: (_a = fields.ButtonText) == null ? void 0 : _a.value,
            formSentText: (_b = fields.FormSentText) == null ? void 0 : _b.value,
            validExtensions: (_c = fields.ValidExtensions) == null ? void 0 : _c.value,
            maxFiles: (_d = fields.MaxFiles) == null ? void 0 : _d.value,
            maxFilesLabel: (_e = fields.MaxFilesLabel) == null ? void 0 : _e.value,
            minFileSize: (_f = fields.MinFileSize) == null ? void 0 : _f.value,
            maxFileSize: (_g = fields.MaxFileSize) == null ? void 0 : _g.value
          }
        )
      ] })
    }
  );
};
var ContactFormWithAttachmentsBlock_default = ContactFormWithAttachmentsBlock;

// src/forms/PoignantCasesForm/PoignantCasesForm.tsx
import { useForm as useForm3 } from "react-hook-form";
import { useSitecoreContext as useSitecoreContext3 } from "@sitecore-jss/sitecore-jss-react";
import { zodResolver as zodResolver3 } from "@hookform/resolvers/zod";

// src/forms/PoignantCasesForm/schema.ts
import { z as z3 } from "zod";
var poignantCasesFormSchema = z3.object({
  initials: z3.string({
    required_error: "this-field-is-required"
    // Written down as the translate keys
  }).max(80, {
    message: "initials-are-too-long"
  }).regex(/^[A-Za-z\s\.]+$/, {
    message: "only-letters-and-spaces-are-allowed"
  }),
  lastName: z3.string({
    required_error: "this-field-is-required"
  }).max(80, {
    message: "lastname-is-too-long"
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  phone: z3.string({
    required_error: "this-field-is-required"
  }).regex(
    /^(((\\+31|0|0031)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))|(((\\+31|0|0031)6){1}[-]?[1-9]{1}[0-9]{7})$/,
    {
      message: "this-is-not-a-valid-dutch-phone-number"
    }
  ),
  email: z3.string({
    required_error: "this-field-is-required"
  }).email({ message: "this-is-not-a-valid-email" }),
  dateofbirth: z3.string({
    required_error: "this-field-is-required"
  }).max(1e3, {
    message: "dateofbirth-is-too-long"
  }).min(1, {
    message: "this-field-is-required"
  }),
  address: z3.string({
    required_error: "this-field-is-required"
  }).max(255, {
    message: "address-is-too-long"
  }).regex(/^.+$/, {
    message: "this-is-not-a-valid-address"
  }),
  postalcode: z3.string({
    required_error: "this-field-is-required"
  }).regex(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i, {
    message: "this-is-not-a-valid-postalcode"
  }),
  placeofresidence: z3.string({
    required_error: "this-field-is-required"
  }).max(1e3, {
    message: "placeofresidence-is-too-long"
  }).min(1, {
    message: "this-field-is-required"
  }),
  policyandbrand: z3.string({
    required_error: "this-field-is-required"
  }).max(1e3, {
    message: "policyandbrand-is-too-long"
  }).min(1, {
    message: "this-field-is-required"
  }),
  contactconsentgiven: z3.literal(true, {
    errorMap: () => ({ message: "contact-consent-not-given" })
  })
}).required({
  initials: true,
  lastName: true,
  phone: true,
  email: true,
  dateofbirth: true,
  placeofresidence: true,
  policyandbrand: true
});

// src/forms/PoignantCasesForm/PoignantCasesForm.tsx
import { Input as Input3, FormGroup as FormGroup3, Checkbox, CheckboxGroup, ArrowRightIcon as ArrowRightIcon2 } from "@achmea/ui";
import { useTranslation as useTranslation4 } from "react-i18next";
import { jsx as jsx6, jsxs as jsxs6 } from "react/jsx-runtime";
var PoignantCasesForm = (props) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const {
    backOfficeMailTemplate,
    confirmationMailTemplate,
    buttonText,
    formSentText,
    className = "",
    ...restProps
  } = props;
  const { sitecoreContext } = useSitecoreContext3();
  const requestVerificationToken = sitecoreContext.requestVerificationToken;
  const { t } = useTranslation4("features");
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm3({
    defaultValues: {
      contactconsentgiven: false
    },
    resolver: zodResolver3(poignantCasesFormSchema)
  });
  const sendBackofficeEmail = useSendTemplatedEmailMutation_default();
  const sendConfirmationEmail = useSendTemplatedEmailMutation_default();
  const isLoading = sendBackofficeEmail.isLoading || sendConfirmationEmail.isLoading;
  const isError = sendBackofficeEmail.isError || sendConfirmationEmail.isError;
  const isSuccess = sendBackofficeEmail.isSuccess && sendConfirmationEmail.isSuccess;
  const onSubmit = async ({ contactconsentgiven, ...restData }) => {
    const data = {
      ...restData,
      contactconsentgiven: contactconsentgiven ? "Ja" : "Nee"
    };
    await sendBackofficeEmail.mutateAsync({
      token: requestVerificationToken,
      templateName: backOfficeMailTemplate,
      data
    });
    await sendConfirmationEmail.mutateAsync({
      token: requestVerificationToken,
      templateName: confirmationMailTemplate,
      data
    });
  };
  const isConsentGiven = watch("contactconsentgiven");
  return /* @__PURE__ */ jsxs6("div", { className: `${className}`, ...restProps, children: [
    /* @__PURE__ */ jsxs6("form", { onSubmit: handleSubmit(onSubmit), className: "form", children: [
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("initials"), error: (_a = errors.initials) == null ? void 0 : _a.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("initials") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("last-name"), error: (_b = errors.lastName) == null ? void 0 : _b.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("lastName") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("date-of-birth"), error: (_c = errors.dateofbirth) == null ? void 0 : _c.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("dateofbirth") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("address"), error: (_d = errors.address) == null ? void 0 : _d.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("address") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("postalcode"), error: (_e = errors.postalcode) == null ? void 0 : _e.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("postalcode") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("placeofresidence"), error: (_f = errors.placeofresidence) == null ? void 0 : _f.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("placeofresidence") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("phone-number"), error: (_g = errors.phone) == null ? void 0 : _g.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("phone") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("email"), error: (_h = errors.email) == null ? void 0 : _h.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("email") }) }),
      /* @__PURE__ */ jsx6(FormGroup3, { label: t("policyandbrand"), error: (_i = errors.policyandbrand) == null ? void 0 : _i.message, children: /* @__PURE__ */ jsx6(Input3, { ...register("policyandbrand") }) }),
      /* @__PURE__ */ jsx6(CheckboxGroup, { label: t("contactconsentgiven"), name: "contactconsentgiven", error: (_j = errors.contactconsentgiven) == null ? void 0 : _j.message, children: /* @__PURE__ */ jsx6(Checkbox, { ...register("contactconsentgiven"), isChecked: isConsentGiven }) }),
      /* @__PURE__ */ jsxs6(
        "button",
        {
          className: "btn btn_primary",
          type: "submit",
          "aria-label": `${t("sends-form")}`,
          disabled: isLoading || isSuccess,
          children: [
            buttonText || t("send"),
            /* @__PURE__ */ jsx6(ArrowRightIcon2, {})
          ]
        }
      )
    ] }),
    isError && /* @__PURE__ */ jsx6("div", { className: "rich-text mt-8", children: t("something-went-wrong-while-sending-your-form") }),
    isSuccess && /* @__PURE__ */ jsx6("div", { className: "rich-text mt-8", children: formSentText || t("your-message-has-been-send") })
  ] });
};
var PoignantCasesForm_default = PoignantCasesForm;

// src/forms/PoignantCasesFormBlock/PoignantCasesFormBlock.tsx
import { Text as Text3, getFieldValue as getFieldValue3 } from "@sitecore-jss/sitecore-jss-react";
import { PageBlockWrapper as PageBlockWrapper3, RichText as RichText3, useIsFieldValid as useIsFieldValid3 } from "@achmea/ui";
import { jsx as jsx7, jsxs as jsxs7 } from "react/jsx-runtime";
var DEFAULT_BLOCK_VARIANT3 = "white";
var DEFAULT_SHOW_DECORATIONS3 = true;
var PoignantCasesFormBlock = (props) => {
  var _a, _b;
  const { fields, className = "", ...restProps } = props;
  const variant = getFieldValue3(
    fields,
    "BlockVariant",
    DEFAULT_BLOCK_VARIANT3
  );
  const showDecorations = getFieldValue3(
    fields,
    "ShowDecorations",
    DEFAULT_SHOW_DECORATIONS3
  );
  const { isTextFieldValid, isRichTextFieldValid } = useIsFieldValid3();
  const showText = isTextFieldValid(fields.Title) || isRichTextFieldValid(fields.RichTextSimple);
  return /* @__PURE__ */ jsx7(
    PageBlockWrapper3,
    {
      variant,
      showDecorations,
      className: `contact-form-block ${className}`,
      ...restProps,
      children: /* @__PURE__ */ jsxs7("div", { className: "contact-form-block__container", children: [
        showText && /* @__PURE__ */ jsxs7("div", { className: "mb-6 xl:mb-8", children: [
          /* @__PURE__ */ jsx7(
            Text3,
            {
              tag: "h2",
              field: fields.Title,
              className: `heading-2 ${isRichTextFieldValid(fields.RichTextSimple) ? "mb-3" : ""}`
            }
          ),
          /* @__PURE__ */ jsx7(RichText3, { field: fields.RichTextSimple })
        ] }),
        /* @__PURE__ */ jsx7(
          PoignantCasesForm_default,
          {
            backOfficeMailTemplate: fields.BackOfficeMailTemplate.value,
            confirmationMailTemplate: fields.ConfirmationMailTemplate.value,
            buttonText: (_a = fields.ButtonText) == null ? void 0 : _a.value,
            formSentText: (_b = fields.FormSentText) == null ? void 0 : _b.value
          }
        )
      ] })
    }
  );
};
var PoignantCasesFormBlock_default = PoignantCasesFormBlock;

// src/forms/PublicationBlock/PublicationBlock.tsx
import { Text as Text4, getFieldValue as getFieldValue4 } from "@sitecore-jss/sitecore-jss-react";
import {
  PageBlockWrapper as PageBlockWrapper4,
  RichText as RichText4,
  useIsFieldValid as useIsFieldValid4,
  Date
} from "@achmea/ui";

// src/forms/Publication/Publication.tsx
import { useForm as useForm4 } from "react-hook-form";
import { useSitecoreContext as useSitecoreContext4, Link } from "@sitecore-jss/sitecore-jss-react";
import { zodResolver as zodResolver4 } from "@hookform/resolvers/zod";

// src/forms/Publication/schema.ts
import { z as z4 } from "zod";
var publicationSchema = z4.object({
  firstName: z4.string({
    required_error: "this-field-is-required"
    // Written down as the translate keys
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  lastName: z4.string({
    required_error: "this-field-is-required"
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  occupation: z4.string({
    required_error: "this-field-is-required"
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  companyName: z4.string({
    required_error: "this-field-is-required"
  }).regex(/^[a-zA-Z\s\-\.']+$/, {
    message: "only-letters-spaces-and-dashes-are-allowed"
  }),
  email: z4.string({
    required_error: "this-field-is-required"
  }).email({ message: "this-is-not-a-valid-email" })
}).required({
  firstName: true,
  lastName: true,
  occupation: true,
  companyName: true,
  email: true
});

// src/forms/Publication/Publication.tsx
import { Input as Input4, FormGroup as FormGroup4, LoadingIcon as LoadingIcon2 } from "@achmea/ui";
import { useTranslation as useTranslation5 } from "react-i18next";
import { jsx as jsx8, jsxs as jsxs8 } from "react/jsx-runtime";
var Publication = (props) => {
  var _a, _b, _c, _d, _e;
  const {
    backOfficeMailTemplate,
    buttonText,
    downloadFile,
    className = "",
    ...restProps
  } = props;
  const { sitecoreContext } = useSitecoreContext4();
  const requestVerificationToken = sitecoreContext.requestVerificationToken;
  const { t } = useTranslation5("features");
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm4({
    resolver: zodResolver4(publicationSchema)
  });
  const sendBackofficeEmail = useSendTemplatedEmailMutation_default();
  const isLoading = sendBackofficeEmail.isLoading;
  const isError = sendBackofficeEmail.isError;
  const isSuccess = sendBackofficeEmail.isSuccess;
  const onSubmit = async (data) => {
    await sendBackofficeEmail.mutateAsync({
      token: requestVerificationToken,
      templateName: backOfficeMailTemplate,
      data
    });
  };
  return /* @__PURE__ */ jsxs8("div", { className: `${className}`, ...restProps, children: [
    /* @__PURE__ */ jsxs8("form", { onSubmit: handleSubmit(onSubmit), className: "form", children: [
      /* @__PURE__ */ jsx8(FormGroup4, { label: t("first-name"), error: (_a = errors.firstName) == null ? void 0 : _a.message, children: /* @__PURE__ */ jsx8(Input4, { ...register("firstName") }) }),
      /* @__PURE__ */ jsx8(FormGroup4, { label: t("last-name"), error: (_b = errors.lastName) == null ? void 0 : _b.message, children: /* @__PURE__ */ jsx8(Input4, { ...register("lastName") }) }),
      /* @__PURE__ */ jsx8(FormGroup4, { label: t("occupation"), error: (_c = errors.occupation) == null ? void 0 : _c.message, children: /* @__PURE__ */ jsx8(Input4, { ...register("occupation") }) }),
      /* @__PURE__ */ jsx8(FormGroup4, { label: t("companyName"), error: (_d = errors.companyName) == null ? void 0 : _d.message, children: /* @__PURE__ */ jsx8(Input4, { ...register("companyName") }) }),
      /* @__PURE__ */ jsx8(FormGroup4, { label: t("email"), error: (_e = errors.email) == null ? void 0 : _e.message, children: /* @__PURE__ */ jsx8(Input4, { ...register("email") }) }),
      /* @__PURE__ */ jsx8("div", { className: "btn-container", children: isSuccess ? /* @__PURE__ */ jsx8(
        Link,
        {
          className: "btn btn_primary",
          field: {
            href: downloadFile
          },
          target: "_blank",
          rel: "noopener noreferrer",
          children: buttonText
        }
      ) : /* @__PURE__ */ jsx8(
        "button",
        {
          className: "btn btn_primary",
          type: "submit",
          "aria-label": `${t("sends-form")}`,
          disabled: isLoading || isSuccess,
          children: isLoading ? /* @__PURE__ */ jsx8(LoadingIcon2, {}) : t("send")
        }
      ) })
    ] }),
    isError && /* @__PURE__ */ jsx8("div", { className: "rich-text mt-8", children: t("something-went-wrong-while-sending-your-form") })
  ] });
};
var Publication_default = Publication;

// src/forms/PublicationBlock/PublicationBlock.tsx
import { jsx as jsx9, jsxs as jsxs9 } from "react/jsx-runtime";
var DEFAULT_BLOCK_VARIANT4 = "white";
var DEFAULT_SHOW_DECORATIONS4 = true;
var PublicationBlock = (props) => {
  var _a, _b;
  const { fields, className = "", ...restProps } = props;
  const variant = getFieldValue4(
    fields,
    "BlockVariant",
    DEFAULT_BLOCK_VARIANT4
  );
  const showDecorations = getFieldValue4(
    fields,
    "ShowDecorations",
    DEFAULT_SHOW_DECORATIONS4
  );
  const { isTextFieldValid, isRichTextFieldValid, isDateFieldValid, isDateFieldSet } = useIsFieldValid4();
  const showText = isTextFieldValid(fields.Title) || isRichTextFieldValid(fields.Body);
  return /* @__PURE__ */ jsx9(
    PageBlockWrapper4,
    {
      variant,
      showDecorations,
      className: `publication-block ${className}`,
      ...restProps,
      children: /* @__PURE__ */ jsxs9("div", { className: "publication-block__container", children: [
        showText && /* @__PURE__ */ jsxs9("div", { className: "mb-6 xl:mb-8", children: [
          /* @__PURE__ */ jsx9(
            Text4,
            {
              tag: "h2",
              field: fields.Title,
              className: "publication-block__title heading-2"
            }
          ),
          isDateFieldValid(fields.Date) && isDateFieldSet(fields.Date) && /* @__PURE__ */ jsx9(
            Date,
            {
              className: "publication-block__date",
              field: fields.Date
            }
          ),
          /* @__PURE__ */ jsx9(
            RichText4,
            {
              field: fields.Body,
              className: "publication-block__subtitle rich-text"
            }
          )
        ] }),
        /* @__PURE__ */ jsx9(
          Publication_default,
          {
            backOfficeMailTemplate: fields.BackOfficeMailTemplate.value,
            buttonText: (_a = fields.DownloadButtonText) == null ? void 0 : _a.value,
            downloadFile: (_b = fields.DownloadFile) == null ? void 0 : _b.value.src
          }
        )
      ] })
    }
  );
};
var PublicationBlock_default = PublicationBlock;

// src/locales/translation-en.json
var translation_en_default = {
  opens: "Opens",
  closes: "Closes",
  window: "window",
  "search-action": "Search",
  "search-placeholder": "Search by name, word, theme",
  "first-name": "First name",
  initials: "Initials",
  "last-name": "Last name",
  "phone-number": "Phone number",
  email: "Email",
  subject: "Subject",
  message: "Message",
  send: "Send",
  "sends-form": "Sends form",
  "something-went-wrong-while-sending-your-form": "Something went wrong while sending your form. Please check your fields and try again.",
  "your-message-has-been-sent": "Your message has been sent.",
  attachments: "Attachments",
  "reset-attachment": "Remove",
  "uploading-file": "Uploading file",
  "error-while-uploading-file": "Error while uploading file",
  "allowed-file-types": "Files of the type {{fileTypes}} are allowed.",
  "minimum-filesize": "The minimum filesize is {{minFileSize}} {{unit}}",
  "maximum-filesize": "The maximum filesize is {{maxFileSize}} {{unit}}",
  "maximum-files-amount": "You can upload a maximum of {{maxFiles}} files.",
  address: "Address (streetname and house number)",
  postalcode: "Postal code",
  placeofresidence: "Place of residence",
  policyandbrand: "Policy number(s) and brand(s)",
  "date-of-birth": "Date of birth",
  contactconsentgiven: "I agree that my email address may be used for further information",
  occupation: "Occupation",
  companyName: "Company name",
  download: "Download"
};

// src/locales/translation-nl-NL.json
var translation_nl_NL_default = {
  opens: "Opent",
  closes: "Sluit",
  window: "venster",
  "search-action": "Zoek",
  "search-placeholder": "Zoek op naam, woord, thema",
  "first-name": "Voornaam",
  initials: "Voorletters",
  "last-name": "Achternaam",
  "phone-number": "Telefoonnummer",
  email: "E-mailadres",
  subject: "Onderwerp",
  message: "Bericht",
  send: "Verzend",
  "sends-form": "Verstuurt formulier",
  "something-went-wrong-while-sending-your-form": "Er is iets misgegaan bij het verzenden van het formulier. Check uw input en probeer daarna opnieuw.",
  "your-message-has-been-sent": "Uw bericht is verzonden.",
  attachments: "Bijlagen",
  "reset-attachment": "Verwijder",
  "uploading-file": "Aan het uploaden",
  "error-while-uploading-file": "Fout tijdens het uploaden van het bestand",
  "allowed-file-types": "Bestanden van het type {{fileTypes}} zijn toegestaan.",
  "minimum-filesize": "De minimale bestandsgrootte is {{minFileSize}} {{unit}}",
  "maximum-filesize": "De maximale bestandsgrootte is {{maxFileSize}} {{unit}}",
  "maximum-files-amount": "U kunt maximaal {{maxFiles}} bestanden uploaden.",
  address: "Adres (straatnaam en huisnummer)",
  postalcode: "Postcode",
  placeofresidence: "Woonplaats",
  policyandbrand: "Polisnummer(s) en merk(en)",
  "date-of-birth": "Geboortedatum",
  contactconsentgiven: "Ik geef akkoord dat mijn e-mailadres mag worden gebruikt voor verdere informatie",
  occupation: "Bezigheid",
  companyName: "Bedrijfsnaam",
  download: "Download"
};

// src/locales/translation-de.json
var translation_de_default = {
  opens: "\xD6ffnet",
  closes: "Schlie\xDFt",
  window: "Fenster",
  "search-action": "Suchen",
  "search-placeholder": "Suchen nach Name, Wort, Thema",
  "first-name": "Vorname",
  initials: "Initialen",
  "last-name": "Nachname",
  "phone-number": "Telefonnummer",
  email: "E-Mail",
  subject: "Betreff",
  message: "Nachricht",
  send: "Senden",
  "sends-form": "Formular senden",
  "something-went-wrong-while-sending-your-form": "Etwas ist beim Senden Ihres Formulars schiefgelaufen. Bitte \xFCberpr\xFCfen Sie Ihre Felder und versuchen Sie es erneut.",
  "your-message-has-been-sent": "Ihre Nachricht wurde gesendet.",
  attachments: "Anh\xE4nge",
  "reset-attachment": "Entfernen",
  "uploading-file": "Datei wird hochgeladen",
  "error-while-uploading-file": "Fehler beim Hochladen der Datei",
  "allowed-file-types": "Dateien vom Typ {{fileTypes}} sind erlaubt.",
  "minimum-filesize": "Die Mindestdateigr\xF6\xDFe betr\xE4gt {{minFileSize}} {{unit}}",
  "maximum-filesize": "Die maximale Dateigr\xF6\xDFe betr\xE4gt {{maxFileSize}} {{unit}}",
  "maximum-files-amount": "Sie k\xF6nnen maximal {{maxFiles}} Dateien hochladen.",
  address: "Adresse (Stra\xDFenname und Hausnummer)",
  postalcode: "Postleitzahl",
  placeofresidence: "Wohnort",
  policyandbrand: "Policenummer(n) und Marke(n)",
  "date-of-birth": "Geburtsdatum",
  contactconsentgiven: "Ich stimme zu, dass meine E-Mail-Adresse f\xFCr weitere Informationen verwendet werden darf.",
  occupation: "Beruf",
  companyName: "Firmenname",
  download: "Herunterladen"
};

// src/locales/translation-fr-CA.json
var translation_fr_CA_default = {
  opens: "Ouvre",
  closes: "Ferme",
  window: "fen\xEAtre",
  "search-action": "Rechercher",
  "search-placeholder": "Rechercher par nom, mot, th\xE8me",
  "first-name": "Pr\xE9nom",
  initials: "Initiales",
  "last-name": "Nom de famille",
  "phone-number": "Num\xE9ro de t\xE9l\xE9phone",
  email: "E-mail",
  subject: "Sujet",
  message: "Message",
  send: "Envoyer",
  "sends-form": "Envoie le formulaire",
  "something-went-wrong-while-sending-your-form": "Une erreur est survenue lors de l'envoi de votre formulaire. Veuillez v\xE9rifier vos champs et r\xE9essayer.",
  "your-message-has-been-sent": "Votre message a \xE9t\xE9 envoy\xE9.",
  attachments: "Pi\xE8ces jointes",
  "reset-attachment": "Supprimer",
  "uploading-file": "T\xE9l\xE9chargement du fichier",
  "error-while-uploading-file": "Erreur lors du t\xE9l\xE9chargement du fichier",
  "allowed-file-types": "Les fichiers du type {{fileTypes}} sont autoris\xE9s.",
  "minimum-filesize": "La taille minimale du fichier est {{minFileSize}} {{unit}}",
  "maximum-filesize": "La taille maximale du fichier est {{maxFileSize}} {{unit}}",
  "maximum-files-amount": "Vous pouvez t\xE9l\xE9charger un maximum de {{maxFiles}} fichiers.",
  address: "Adresse (nom de la rue et num\xE9ro de la maison)",
  postalcode: "Code postal",
  placeofresidence: "Lieu de r\xE9sidence",
  policyandbrand: "Num\xE9ro(s) de police et marque(s)",
  "date-of-birth": "Date de naissance",
  contactconsentgiven: "J'accepte que mon adresse e-mail soit utilis\xE9e pour des informations suppl\xE9mentaires",
  occupation: "Profession",
  companyName: "Nom de l'entreprise",
  download: "T\xE9l\xE9charger"
};
export {
  ContactForm_default as ContactForm,
  ContactFormBlock_default as ContactFormBlock,
  ContactFormWithAttachments_default as ContactFormWithAttachments,
  ContactFormWithAttachmentsBlock_default as ContactFormWithAttachmentsBlock,
  PoignantCasesForm_default as PoignantCasesForm,
  PoignantCasesFormBlock_default as PoignantCasesFormBlock,
  PublicationBlock_default as PublicationBlock,
  UploadInput_default as UploadInput,
  contactFormSchema,
  contactFormWithAttachmentsSchema,
  translation_de_default as translationsDE,
  translation_en_default as translationsEN,
  translation_fr_CA_default as translationsFR,
  translation_nl_NL_default as translationsNL,
  useSendTemplatedEmailMutation_default as useSendTemplatedEmailMutation,
  useUploadFileMutation_default as useUploadFileMutation
};
