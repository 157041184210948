import React from 'react';
import {
  HomePageLayoutComponentFields,
  HomePageLayoutComponentProps,
  ScrollableTextItem,
} from '../../../components/HomePageLayout';
import {
  Image,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ScrollContainer from '../../scrollytelling/ScrollContainer/ScrollContainer';
import PageCarouselComposition from '../../scrollytelling/PageCarouselComposition/PageCarouselComposition';
import PageBackgroundComposition from '../../scrollytelling/PageBackgroundComposition/PageBackgroundComposition';
import HeroComposition from '../../scrollytelling/HeroComposition/HeroComposition';
import {
  cardSliderMotionPath,
  firstRoundedImageMotionPath,
  getScrollLinkedImagesOverlayProps,
  secondRoundedImageMotionPath,
  statementMotionPath,
} from './transitions';
import OverlayImagesComposition from '../../scrollytelling/OverlayImagesComposition/OverlayImagesComposition';
import {
  PageLayout,
  useScreenDimensions,
  usePageTheme,
  ThemeProvider,
  Footer,
  HeaderComposition,
  useSmoothScroll,
  Reel,
  ImageCard,
  PreHeader,
} from '@achmea/ui';
import { Timeline } from '../../scrollytelling/types';
import { mapKeyframes, mapTimelineToRange } from '../../scrollytelling/utils';
import MoveContentComposition from '../../scrollytelling/MoveContentComposition/MoveContentComposition';

export interface HomePageProps
  extends React.HTMLAttributes<HTMLDivElement>,
  HomePageLayoutComponentProps { }

const EASE_IN_OUT_CUBIC = (t: number) => {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

const HomePage = (props: HomePageProps) => {
  const { className = '', children, ...restProps } = props;

  const { sitecoreContext } = useSitecoreContext();
  const pageFields = (sitecoreContext.route?.fields ||
    {}) as unknown as HomePageLayoutComponentFields;

  const heroTitle = pageFields.HeroTitle;
  const videoUrl = pageFields.VideoTarget;

  const videoButtonTitle = pageFields.VideoButtonTitle;
  const videoButtonTarget = pageFields.VideoButtonTarget;

  const statements = pageFields.ScrollableTextItems.map(
    (item: ScrollableTextItem) => item.fields.Title,
  );

  const statement4 = pageFields.AnimatedTitle;

  const firstRoundedImage = pageFields.FirstRoundedImage;
  const secondRoundedImage = pageFields.SecondRoundedImage;

  const overlayImagesText = pageFields.AnimatedLogoTitle;

  const overlayImagesData = getScrollLinkedImagesOverlayProps(
    pageFields.AnimatedLogos,
  );

  const cardSliderTitle = pageFields.CardSliderTitle;
  const cardSliderItems = pageFields.CardSliderItems;

  const carouselTitle = pageFields.CarouselTitle;
  const carouselItems = pageFields.CarouselItems as [];

  const theme = usePageTheme();
  const { height } = useScreenDimensions();
  const scrollTo = useSmoothScroll(1.5 * Number(height), {
    duration: 1500,
    easingFn: EASE_IN_OUT_CUBIC,
  });

  const duration = 5.5;

  const timeline: Timeline = {
    header: [0, 1],
    hero: [0.7, 1],
    statement1: [1, 2],
    statement2: [2, 3],
    statement3: [3, 4],
    firstRoundedImage: [1, 2.5],
    secondRoundedImage: [3, 4.5],
    statement4: [3.5, 4.5],
    overlayImages: [4, 5],
    cardSlider: [4.5, 5.5],
    pageCarousel: [5, 5.5],
    pageBackground: [0, 5.5],
  };

  const ranges = mapTimelineToRange(timeline, [0, duration], [0, 1]);

  const keyframes: Record<string, number[]> = {
    header: [0, 1],
    hero: [0, 1],
    statement1: [0, 0.4, 0.8, 1],
    statement2: [0, 0.5, 0.5, 1],
    statement3: [0, 0.2, 0.5, 0.7],
    firstRoundedImage: [0.2, 0.33, 0.33, 1],
    secondRoundedImage: [0.2, 0.33, 0.33, 0.66],
    statement4: [0, 0.5, 0.5, 1],
    overlayImages: [0, 0.5, 0.5, 1],
    cardSlider: [0, 0.5, 0.5, 1],
    pageCarousel: [0, 1],
    pageBackground: [
      0,
      0.18,
      /**
       * First background color change
       *
       * Scrollduration = 5.5
       * Matching scroll effect range (from statement4) => 3.5
       *
       * Result
       * (3.5/5.5) => 0.64
       */
      0.64,
      0.7,
      0.71,
      0.72,
      0.75, // Second background color change
      0.82,
      0.96,
      1,
    ],
  };

  keyframes.decorations = [
    mapKeyframes(keyframes.header.slice(0, -1), [0, 1], ranges.header),
    mapKeyframes(
      keyframes.firstRoundedImage.slice(1, -1),
      [0, 1],
      ranges.firstRoundedImage,
    ),
    mapKeyframes(keyframes.statement2.slice(1, -1), [0, 1], ranges.statement2),
    mapKeyframes(
      keyframes.secondRoundedImage.slice(1, -1),
      [0, 1],
      ranges.secondRoundedImage,
    ),
    mapKeyframes(keyframes.statement4.slice(1, -1), [0, 1], ranges.statement4),
    mapKeyframes(
      keyframes.overlayImages.slice(1, -1),
      [0, 1],
      ranges.overlayImages,
    ),
    mapKeyframes(keyframes.cardSlider.slice(1, -1), [0, 1], ranges.cardSlider),
    mapKeyframes(keyframes.pageCarousel.slice(1), [0, 1], ranges.pageCarousel),
  ].flat();

  return (
    <ThemeProvider theme={theme}>
      <div className={`home-page ${className}`} {...restProps}>
        <PageLayout>
          <PageLayout.Header position="fixed">
            <PreHeader />
            <HeaderComposition
              keyframes={{ logo: keyframes.header, header: keyframes.header }}
              scrollEffectRange={ranges.header}
              showInitialHint={true}
              variant="peek"
            />
          </PageLayout.Header>
          <PageLayout.Main>
            <ScrollContainer scrollDuration={duration}>
              {({ progress }) => (
                <>
                  <PageBackgroundComposition
                    progress={progress}
                    videoUrl={videoUrl.value.href}
                    videoButton={{
                      title: videoButtonTitle,
                      url: videoButtonTarget,
                    }}
                    keyframes={{
                      decorations: keyframes.decorations,
                      background: keyframes.pageBackground,
                    }}
                    scrollEffectRange={ranges.pageBackground}
                    onClickNext={() => {
                      scrollTo();
                    }}
                  />
                  <HeroComposition
                    title={heroTitle}
                    progress={progress}
                    keyframes={{
                      titleExit: keyframes.hero,
                      buttonExit: keyframes.hero,
                    }}
                    scrollEffectRange={ranges.hero}
                  />
                  <MoveContentComposition
                    motionPath={firstRoundedImageMotionPath}
                    progress={progress}
                    keyframes={keyframes.firstRoundedImage}
                    scrollEffectRange={ranges.firstRoundedImage}
                  >
                    <div className="home-page__rounded-image-wrapper">
                      <Image
                        field={firstRoundedImage}
                        className="home-page__rounded-image"
                      />
                    </div>
                  </MoveContentComposition>
                  <MoveContentComposition
                    motionPath={secondRoundedImageMotionPath}
                    progress={progress}
                    keyframes={keyframes.secondRoundedImage}
                    scrollEffectRange={ranges.secondRoundedImage}
                  >
                    <div className="home-page__rounded-image-wrapper">
                      <Image
                        field={secondRoundedImage}
                        className="home-page__rounded-image"
                      />
                    </div>
                  </MoveContentComposition>
                  {statements.map((statement, index) => (
                    <MoveContentComposition
                      key={index}
                      motionPath={statementMotionPath}
                      progress={progress}
                      fadeIn={true}
                      fadeOut={true}
                      keyframes={keyframes[`statement${index + 1}`]}
                      scrollEffectRange={ranges[`statement${index + 1}`]}
                    >
                      <Text
                        tag="h2"
                        field={statement}
                        className="home-page__statement"
                      />
                    </MoveContentComposition>
                  ))}
                  <MoveContentComposition
                    motionPath={statementMotionPath}
                    progress={progress}
                    fadeOut={true}
                    keyframes={keyframes.statement4}
                    scrollEffectRange={ranges.statement4}
                  >
                    <Text
                      tag="h2"
                      field={statement4}
                      className="home-page__statement home-page__statement_dark"
                    />
                  </MoveContentComposition>
                  <OverlayImagesComposition
                    {...overlayImagesData}
                    progress={progress}
                    keyframes={{
                      title: keyframes.overlayImages,
                      overlayImage: keyframes.overlayImages,
                    }}
                    scrollEffectRange={ranges.overlayImages}
                  >
                    <Text
                      tag="h2"
                      className="home-page__statement home-page__statement_dark"
                      field={overlayImagesText}
                    />
                  </OverlayImagesComposition>
                  {cardSliderItems && (
                    <MoveContentComposition
                      motionPath={cardSliderMotionPath}
                      keyframes={keyframes.cardSlider}
                      progress={progress}
                      scrollEffectRange={ranges.cardSlider}
                      className="home-page__card-slider"
                    >
                      <div className="container_normal">
                        <div className="home-page__card-slider-text">
                          <Text
                            tag="h2"
                            className="heading-2 mb-3 sm:mb-4"
                            field={cardSliderTitle}
                          />
                        </div>
                        <Reel className="home-page__card-slider-reel">
                          {cardSliderItems.map((card, index) => (
                            <div
                              className="home-page__card-slider-card"
                              key={index}
                            >
                              <ImageCard fields={card.fields} />
                            </div>
                          ))}
                        </Reel>
                      </div>
                    </MoveContentComposition>
                  )}
                  {carouselItems && (
                    <PageCarouselComposition
                      title={carouselTitle}
                      items={carouselItems}
                      progress={progress}
                      keyframes={keyframes.pageCarousel}
                      scrollEffectRange={ranges.pageCarousel}
                    />
                  )}
                </>
              )}
            </ScrollContainer>
          </PageLayout.Main>
          <PageLayout.Footer>
            <Footer />
          </PageLayout.Footer>
        </PageLayout>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
